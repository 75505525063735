.ant-table table {
    border-spacing: 0 10px !important;
    // border-collapse: collapse !important;
    background-color: #F0F2F5 !important;
}

.ant-table-thead {
    background-color: #4a9ef7;
    .ant-table-cell {
        color: white !important;
        background-color: transparent !important;
    }
}

table tr td{
    padding: 18px !important;
    background-color:white;
    font-size: 12px;
    color: gray;
}


table th{
    font-size: 14px;
    font-weight: bold;
    color: gray;
    opacity: 0.8;
}
