.ant-form-item {
    position: relative;

    .ant-form-item-label {
        position:absolute;
        z-index: 1;
        left: 10px;
        
        label {
            color: #108ee9;
            font-weight: 600;
            opacity: 0.8; 
            font-size: 10px;
        }
    }

    .ant-select-selection-search-input {
        margin-top:20px !important;
    }

    .ant-select-selector {
        height: 50px !important;
        padding: 20px 20px 0px !important;
    }

}