.page-header-toolbar {
    background-color: white;
    width: 100%;
    padding: 18px;
    margin-bottom: 24px;
    border-top: 2px solid #86bdf7;

    h1 {
        font-size: 28px;
        color: gray;
        margin: 0;
    }

    .search {
        border-radius: 8px;
        margin: 4px;
    }

    .filter {
        width: 100%;
        height: 38px;
        background-color: #4A9EF7;
        border-radius: 8px;
        margin: 4px;

        span {
            color: #FFFFFF;
            margin: 0px 4px;
            font-weight: bold;
        }

        .fade {
            opacity: 0.8;
            font-weight: 400;
        }
    }

    .dropdown-filter {
        width: 100%;
        height: 38px;
        background-color: #4A9EF7;
        border-radius: 8px;
        margin: 4px;

        span {
            color: #FFFFFF;
            margin: 0px 4px;
            font-weight: bold;
        }

        .fade {
            opacity: 0.8;
            font-weight: 400;
        }
    }

    .btn-circle {
        background-color: #4A9EF7;
        margin: 4px;

        svg {
            color: #FFFFFF;
        }
    }
}