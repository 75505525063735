@import url('../assets/styles/input.scss');
@import url('../assets/styles/password.scss');
@import url('../assets/styles/picker.scss');
@import url('../assets/styles/buttons.scss');
@import url('../assets/styles/select.scss');
@import url('../assets/styles/table.scss');
@import url('../assets/styles/dropzone.scss');
@import url('../assets/styles/custom.scss');

.ant-layout {
    background: unset !important;
}

.d-flex {
    display: flex;
}