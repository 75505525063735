.ant-form-item {
    position: relative;

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        z-index: unset;
    }

    .ant-input-affix-wrapper-focused,
    .ant-input-affix-wrapper:focus {
        z-index: unset;
    }


    .ant-form-item-label {
        position: absolute;
        z-index: 1;
        left: 10px;

        label {
            color: #108ee9;
            font-weight: 600;
            opacity: 0.8;
            font-size: 10px;
        }
    }

    .ant-input {
        padding: 24px 11px 4px 20px !important;
        font-size: 14px;
        // margin-bottom: 12px;
    }
}